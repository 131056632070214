<template>
    <div class="type-radio-component">
        <template v-for="(item, index) in list">
            <div :class="['item',item.key === active?'active':'']" :disabled="item.disabled" :key="index" @click="itemClick(item)">{{item.name}}</div>
        </template>
    </div>
</template>
<script>
export default {
    props:{
        list:{
            type: Array,
            default: []
        },
        active:{
            type: String,
            default: ''
        }
    },
    methods:{
        itemClick(item){
            this.$emit('change',item);
        }
    }
}
</script>
<style lang="scss" scoped>
.type-radio-component{
    display: flex;
    > .item{
        border: 1px solid #000000;
        box-sizing: border-box;
        padding: 8px 15px;
        font-size: 14px;
        margin-right: 15px;
        border-radius: 50px;
        cursor: pointer;
        &.active{
            color: #ffffff;
            background-color: #000000;
            transition: background-color .3s linear;
            pointer-events: none;
        }
        &[disabled],&:disabled{
            cursor: not-allowed;
            pointer-events: none;
            opacity: .5;
        }
    }
}
</style>
